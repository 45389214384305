* {
  
  box-sizing: border-box;

}

html {
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
}
body {
    
    text-align: center;
    background-color: black;
    margin: 0;
    height: 100%;
  
    
  }

  a {
    text-decoration: none;
  }

.OneToFifty-Container {
    margin: auto;
    width: 100vw;
    height: 100vh;
    border: 1px solid black;
    position: relative;
    background-image: url("background1.png");
    background-size:cover;


  
    
}

.OneToFifty-Container-Overlay {
  margin: auto;
  width: 100vw;
  height: 100vh;
  border: 1px solid black;
  position: relative;
  background: url("background.png") rgba(36, 0, 0, 0.8);
  background-blend-mode: multiply;
  background-size:cover;   
}

.Game-Container {
  margin-top: 60px;
  
 
}

.title {
    color: white;
    font-size: 4rem;
}
.Social-Icon {
 width: 100%;
}
.Social-Icon-Overlay {
display: none;
}

.Outer-Board-Container {
    width: 100%;
    /* height: 100%; */
    /* background-image: url("woodboard.png"); */
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 20px auto; */

}
.Outer-Board-Container-Overlay {
    width: 100%;
    
 
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}


.Board-Container {
    width: 380px;
    height: 380px;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    /* background-color:rgb(65, 65, 221); */
 
    
    /* border: 3px solid black; */
    border-radius: 10%;
    
    
    
 

}

.Board-Container-Overlay {
 display: none;
}
.Cell-Container {
    
    margin: 5px;
  
   
    font-size: 4rem;
    font-weight:800;
    cursor: pointer;
    background-color:#FF9101;
    box-shadow: 0 7px  #843a05; 
    color: rgb(255, 255, 255);
    


    font-family:Verdana, Geneva, Tahoma, sans-serif;
    border-radius: 7px;
    

    
   
    
}

.btn {
   
        text-align: center;
        outline: none;
        border: none;
        font-size: 1.5rem ;
        /* display: inline-block; */
        margin: 5px 5px 5px 5px ; 
        font-weight: bold;
        padding: 10px 10px 10px 10px ;
        background-color: #FCD24F;
        
        color: black;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -o-border-radius: 7px;
        border-radius: 7px;
        box-shadow: 0 7px  #EA8439; 
        cursor: pointer;
      
}

.btn:hover {
    background-color: #FCDB72;
  
}

.btn:active {
    background-color: #FCDB72;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.btn-overlay {
    display: none;
}



.Timer-Container {
  
   
    width: 135px;
    height: 50px;
    margin: 20px auto;
    
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    border-radius: 10px;
    background-color: white;
    border: 3px solid black;
}


.Timer-Front {
    text-align: right;
}

.Timer-Back {
    width: 1em;
}

.Scoreboard {

    position: absolute;
    width: 90%;
 
    margin: auto;
    ;


   
}

.Result {
  width: 90%;
  text-align: center;
  margin: auto;

    /* width: 80%;
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);  */
  
 
}

.Result-Score-Container {
  display: flex;
  max-width: 400px; 
  margin: auto;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;
  margin-bottom: 20px; /* Space between the result score and the leaderboard */
  background-color: #FCD24F;; /* Light cyan background for emphasis */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px #A85115;; /* Subtle shadow for depth */
}

.Result-Label {
  font-weight: bold;
  font-size: 2.5em;
  color: #3CA025; /* Deep teal for the label */
}

.Score-Title {
  margin-top: 30px;
  font-weight: bold;
  font-size: 1.2em;
  color: #3CA025; /* Deep teal for the label */
}

.Result-Value {
  font-weight: bold;
  font-size: 2.5em;
  color:#3CA025; /* Darker teal for the score */
  padding-left: 10px;
}


.Highscore-Board {
  max-width: 400px; /* Limit the width of the leaderboard */
  margin: 20px auto; /* Center the leaderboard on the page */
  padding: 20px;
  border: 15px solid #A85115;
  background-color: #FCD24F;; /* Light gray background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-family: 'Arial', sans-serif; /* Clean font */
}

.High-Score {
  text-align: center; /* Center the heading text */
  margin-bottom: 20px;
  font-size: 1.5em; /* Slightly larger font for the heading */
  color: #333; /* Darker text color */
  border-bottom: 2px solid ; /* Blue underline */
  padding-bottom: 10px;
}

.High-Score-Item {
  display: flex;
  justify-content: space-between; /* Space between rank and time */
  align-items: center; /* Align items in the center vertically */
  padding: 10px 0;
  border-bottom: 1px solid burlywood; /* Light border between items */
}

.High-Score-Item:last-child {
  border-bottom: none; /* Remove the border from the last item */
}

.Rank {
  font-weight: bold;
  font-size: 1.2em; /* Larger size for the ranking number */
  color:#00796b; /* Blue color for ranking number */
}

.Time {
  font-size: 1.2em;
  color: #555; /* Dark gray for the time */
}

.btn-playagain {
  background-color: #3CA025; /* Blue background color */
  /* background-color: #007BFF;  */
  color: white;
  padding: 12px 24px; /* Padding around the text */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Bold text */
  border: none; /* Remove the default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effects */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.btn-playagain:hover {
  background-color: #47B72F; /* Darker blue on hover */
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.btn-playagain:active {
  background-color: #004085; /* Even darker blue when active (clicked) */
  transform: scale(1); /* Reset scale on click */
}

.btn-playagain:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Add a focus ring for accessibility */
}
@media screen and (max-width: 767px) {
    .Result-Score {
        font-size: 2.5rem;
    }

    .Timer-Container {
  
     
        margin: 10px auto 20px;
        
    
     
    }

    .Timer-Container {
  
   
        /* width: 100px;
        height: 25px;
     
        
      
        font-size: 2rem;
     */
     
    }
    
  }
  /* TABLET */
  @media screen and (min-width: 767px) and (max-width: 992px) {
    .Result-Score { 
        font-size: 3.5rem;
    }
  }




